const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_CURRENT_VALUE: 'user/SET_CURRENT_VALUE',
  SET_ANALYTICS: 'user/SET_ANALYTICS',
  UPDATE_POSITION: 'user/UPDATE_POSITION',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  RESEND_EMAIL: 'user/RESEND_EMAIL',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOAD_CURRENT_PORTFOLIO: 'user/LOAD_CURRENT_PORTFOLIO',
  QUIET_LOAD_PORTFOLIO: 'user/QUIET_LOAD_PORTFOLIO',
  LOAD_LABELS: 'user/LOAD_LABELS',
  UPDATE_LABELS: 'user/UPDATE_LABELS',
  LOGOUT: 'user/LOGOUT',
  FORGOT: 'user/FORGOT',
}

export default actions
