import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Modal, Button } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  //  TODO: Evaluate whether we want a tagline and how to accomodate styling for it
  //  tagline: settings.tagline,
  // isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
})

const AuthLayout = ({
  children,
  //  tagline,
  // isGrayTopbar,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
}) => {
  const [visible, setVisible] = useState(false)

  function handleOk() {
    setVisible(false)
  }

  function handleCancel() {
    setVisible(false)
  }
  const myPolicy = 76982377

  useEffect(() => {
    const s = document.createElement('script')
    const tag = document.getElementsByTagName('script')[0]

    s.src = 'https://cdn.iubenda.com/iubenda.js'

    tag.parentNode.insertBefore(s, tag)
  }, [])
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            [style.gray]: authPagesColor === 'gray',
          })}
        >
          <div className={style.containerInner}>{children}</div>
          <div className="mt-auto pb-5 pt-5">
            <ul
              className={`${style.footerNav} list-unstyled d-flex mb-0 flex-wrap justify-content-center`}
            >
              <li>
                <a
                  role="button"
                  onClick={setVisible}
                  tabIndex="0"
                  styling="link"
                  onKeyDown={setVisible}
                >
                  {' '}
                  Disclaimer
                </a>
              </li>
              <li>
                <a
                  href={`https://www.iubenda.com/privacy-policy/${myPolicy}`}
                  className="iubenda-nostyle no-brand iubenda-embed"
                  title="Privacy Policy"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={`https://www.iubenda.com/api/privacy-policy/${myPolicy}/cookie-policy`}
                  className="iubenda-nostyle no-brand iubenda-embed"
                  title="Cookie Policy"
                >
                  Cookie Policy
                </a>
              </li>
              <li>
                <a
                  href={`https://www.iubenda.com/api/terms-and-conditions/${myPolicy}`}
                  className="iubenda-nostyle no-brand iubenda-embed"
                  title="Terms and Conditions"
                >
                  Terms and conditions
                </a>
              </li>
            </ul>
            <div className="text-center">Copyright © {new Date().getUTCFullYear()} SwingTrackr</div>
          </div>
          <Modal
            title="Disclaimer"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="submit" type="primary" onClick={handleOk}>
                Okay
              </Button>,
            ]}
          >
            <p>
              SwingTrackr is for informational purposes only as SwingTrackr is not registered as a
              securities broker-dealer or an investment adviser. No information herein is intended
              as securities brokerage, investment, tax, accounting or legal advice, as an offer or
              solicitation of an offer to sell or buy, or as an endorsement, recommendation or
              sponsorship of any company, security or fund.
            </p>
            <p>
              SwingTrackr cannot and does not assess, verify or guarantee the adequacy, accuracy or
              completeness of any information, the suitability or profitability of any particular
              investment, or the potential value of any investment or informational source. The
              reader bears responsibility for his/her own investment research and decisions, should
              seek the advice of a qualified securities professional before making any investment,
              and investigate and fully understand any and all risks before investing.
            </p>
            <p>
              SwingTrackr in no way warrants the solvency, financial condition, or investment
              advisability of any of the securities mentioned in communications or websites. In
              addition, SwingTrackr accepts no liability whatsoever for any direct or consequential
              loss arising from any use of this information. This information is not intended to be
              used as the sole basis of any investment decision, should it be construed as advice
              designed to meet the investment needs of any particular investor. Past performance is
              not necessarily indicative of future returns.
            </p>
          </Modal>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(AuthLayout))
