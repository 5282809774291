import React, { useState } from 'react'
import {
  notification,
  Modal,
  Form,
  Input,
  InputNumber,
  Steps,
  Typography,
  Space,
  Button,
  Popover,
} from 'antd'
import TickerAutoCompleteList from 'components/tickers/TickerAutoCompleteList'
import { BackendHelper } from 'utils/index'
import { connect } from 'react-redux'
import './styles.scss'
import './override.css'

const mapStateToProps = ({ settings, user }) => ({
  addPositionModalIsOpen: settings.addPositionModalIsOpen,
  currentPortfolio: user.currentPortfolio,
})

const AddPositionModal = (props) => {
  const { dispatch, addPositionModalIsOpen, currentPortfolio } = props

  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState(0)

  const closePositionModal = (f) => {
    f.resetFields()

    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'addPositionModalIsOpen',
        value: false,
      },
    })
  }

  const [form] = Form.useForm()
  const { Title, Paragraph } = Typography
  const { Step } = Steps

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const finish = (f) => {
    setCurrent(0)
    closePositionModal(f)
  }

  function resetModal() {
    setCurrent(0)
  }

  const onCreatePosition = (values) => {
    setLoading(true)

    createPosition(values.ticker, values.goal, values.scale).then((data) => {
      dispatch({
        type: 'user/UPDATE_POSITION',
        payload: {
          updatedPosition: {
            ...data,
            journalEntries: [],
          },
        },
      })

      form.resetFields()
      setCurrent(2)
      setLoading(false)

      notification.success({
        message: 'Portfolio Updated',
        description: `A new position for the "${data.symbol}" ticker was created!`,
      })
    })
  }

  function createPosition(ticker, goal = 15, scale = 5) {
    const body = {
      symbol: ticker.symbol,
      exchange: ticker.exchange,
      goal: Number(goal),
      scale: Number(scale),
    }

    return BackendHelper.post(`portfolios/${currentPortfolio.id}/positions`, { body })
  }

  const steps = [
    {
      title: 'Welcome',
      content: (
        <div className="text-center content">
          <i className="fe fe-align-justify icon" />
          <Title level={4} className="model-title">
            Welcome to SwingTrackr Positions!
          </Title>
          <Paragraph className="text-center">
            The next series of steps is going to help guide you in adding a position
          </Paragraph>
        </div>
      ),
    },
    {
      title: 'General',
      content: (
        <div className="content">
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: 'public' }}
          >
            <Form.Item
              name="ticker"
              label="Ticker Symbol"
              hasFeedback
              rules={[{ required: true, message: 'Your swing needs a stock!' }]}
            >
              <TickerAutoCompleteList
                exclude={(currentPortfolio?.positions || []).filter((s) => s.status === 'open')}
              />
            </Form.Item>
            <Popover
              overlayStyle={{ width: 300 }}
              placement="topLeft"
              content={<div>Goal is your percentage gain goal you have set for your swing. </div>}
            >
              <Form.Item
                name="goal"
                label="Goal (%)"
                hasFeedback
                rules={[{ required: true, message: 'Your swing needs a goal!' }]}
              >
                <InputNumber
                  type="textarea"
                  min={0}
                />
              </Form.Item>
            </Popover>
            <Popover
              overlayStyle={{ width: 300 }}
              placement="topLeft"
              content={
                <div>
                  Scale is your dollar cost averaging plan or simply how many times you plan on
                  purchasing a given financial instrument{' '}
                </div>
              }
            >
              <Form.Item
                name="scale"
                hasFeedback
                label="Scale (eg. 3/5/10)"
                rules={[{ required: true, message: 'Your swing needs a scale!' }]}
              >
                <Input type="textarea" />
              </Form.Item>
            </Popover>
          </Form>
        </div>
      ),
    },
    {
      title: 'Add more',
      content: (
        <div className="text-center content">
          <Title level={3} className="model-title">
            A position has been added to your portfolio!
          </Title>
          <Paragraph className="text-center">
            Do you want to add another position? Just follow the prompt below, or click
            &quot;Finish&quot; to exit this screen.
          </Paragraph>
        </div>
      ),
    },
  ]

  const modalFooter = (
    <Space>
      {current === 0 && <Button onClick={() => next()}>Next</Button>}

      {current === 1 && (
        <div>
          <Button onClick={() => prev()}>Previous</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => {
              form
                .validateFields()
                .then(onCreatePosition)
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          >
            Add Position
          </Button>
        </div>
      )}

      {current === 2 && (
        <div>
          <Button onClick={() => finish(form)}>Finish</Button>
          <Button type="primary" loading={loading} onClick={() => resetModal()}>
            Add Position
          </Button>
        </div>
      )}
    </Space>
  )

  return (
    <Modal
      className="base-modal"
      title="Add a new position"
      centered
      visible={addPositionModalIsOpen}
      onCancel={() => closePositionModal(form)}
      style={{ borderStyle: 'solid', borderColor: 'white' }}
      confirmLoading={loading}
      footer={modalFooter}
    >
      <div>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps)(AddPositionModal)
