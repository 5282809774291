import { capitalCase } from 'change-case'

export default function formatLabel(text, exclude = []) {
  const ex = {}
  let excludedText = text

  exclude.forEach((x, i) => {
    const ph = `P${i}`

    ex[ph] = x
    excludedText = excludedText.replace(x, `_${ph}_`)
  })

  let output = capitalCase(excludedText)

  Object.entries(ex).forEach(([k, v]) => {
    output = output.replace(k, v)
  })

  return output
}
