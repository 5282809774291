import pupa from 'pupa'
import { format as formatDate } from 'date-fns'
import * as MaterialColours from 'material-ui-colors'

export function titleCase(string) {
  const result = string
    .replace(/([a-z])([A-Z][a-z])/g, '$1 $2')
    .replace(/([A-Z][a-z])([A-Z])/g, '$1 $2')
    .replace(/([a-z])([A-Z]+[a-z])/g, '$1 $2')
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, '$1 $2')
    .replace(/([a-z]+)([A-Z0-9]+)/g, '$1 $2')

    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, '$1 $2')
    .replace(/([0-9])([A-Z][a-z]+)/g, '$1 $2')

    .replace(/([A-Z]{2,})([0-9]{2,})/g, '$1 $2')
    .replace(/([0-9]{2,})([A-Z]{2,})/g, '$1 $2')
    .replace(/_/g, ' ')
    .replace(/&/g, ' & ')
    .trim()

  // capitalize the first letter
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export function sortByOptions(options, field) {
  /* eslint-disable no-param-reassign */
  const map = options.reduce((a, c, i) => {
    a[c] = i
    return a
  }, {})
  /* eslint-enable no-param-reassign */

  return (a, b) => {
    if (map[a[field]] < map[b[field]]) {
      return -1
    }

    if (map[a[field]] > map[b[field]]) {
      return 1
    }

    return 0
  }
}

export function fillTextTemplate(template, context) {
  return pupa(template, context)
}

export function getPropertyValue(obj, path, defaultValue) {
  const [head, ...rest] = path.split(/[\.\[\]\'\"]/).filter((p) => p)

  return rest.length === 0 ? obj[head] || defaultValue : getPropertyValue(obj[head], rest.join('.'))
}

export function setPropertyValue(obj, path, value) {
  const [head, ...rest] = path.split(/[\.\[\]\'\"]/).filter((p) => p)

  if (rest.length === 0) {
    obj[head] = value
    return
  }

  if (!obj[head]) {
    obj[head] = !Number.isNaN(rest[0]) && Number.isInteger(+rest[0]) ? [] : {}
  }

  setPropertyValue(obj[head], rest.join('.'), value)
}

export function standardDateFormat(dateString) {
  return formatDate(new Date(dateString), 'MMMM dd. yyyy')
}

// Default colours for various portions of the software
const materialColours = Object.values(MaterialColours).reduce(
  (a, c) => a.concat([c[400], c[200], c[900], c.A400].filter(Boolean)),
  [],
)
const suggestedColours = ['#46be8a', '#fb434a', '#2f8ceb']

export const defaultColours = [...suggestedColours, ...materialColours]

export function getUniqueColour(excludedColours = []) {
  const colour = defaultColours[Math.floor(Math.random() * defaultColours.length)]

  if (excludedColours.length !== defaultColours.length && excludedColours.includes(colour)) {
    return getUniqueColour(excludedColours)
  }

  return colour
}
