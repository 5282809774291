import { all, takeEvery, put, call, select } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import store from 'store'
import * as expirePlugin from 'store/plugins/expire'
import * as firebase from 'services/firebase'
import * as jwt from 'services/jwt'
import { BackendHelper, Misc } from 'utils/index'
import actions from './actions'

const mapAuthProviders = {
  firebase: {
    login: firebase.login,
    register: firebase.register,
    currentAccount: firebase.currentAccount,
    logout: firebase.logout,
    forgot: firebase.forgot,
  },
  jwt: {
    login: jwt.login,
    register: jwt.register,
    currentAccount: jwt.currentAccount,
    logout: jwt.logout,
  },
}

store.addPlugin(expirePlugin)

export function* SET_CURRENT_VALUE({ payload: { setting, value } }) {
  yield store.set(`app.user.${setting}`, value)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      [setting]: value,
    },
  })
}

export function* SET_ANALYTICS({ payload: { value } }) {
  const expireDate = new Date().getTime() + 86400000
  yield store.set(`app.user.analytics`, value, expireDate)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      analytics: value,
    },
  })
}

export function* LOGIN({ payload }) {
  const { email, password } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider: autProviderName } = yield select((state) => state.settings)

  const success = yield call(mapAuthProviders[autProviderName].login, email, password)

  if (success) {
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    })

    yield history.push('/')

    yield notification.success({
      message: 'Logged In',
      description: 'You have successfully logged in!',
    })
  }

  if (!success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    })
  }
}

export function* RESEND_EMAIL() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { success } = yield call(BackendHelper.post, 'user/resend_verification')

  if (!success) {
    notification.warn({
      message: 'Verification Timeout',
      description: 'Please wait a few minutes before requesting a new verification email.',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* REGISTER({ payload }) {
  const { email, password, firstName, lastName } = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider } = yield select((state) => state.settings)
  const success = yield call(
    mapAuthProviders[authProvider].register,
    email,
    password,
    firstName,
    lastName,
  )

  if (success) {
    notification.success({
      message: 'Verification Email Sent',
      description: `A verification email has been sent to ${email}!`,
    })

    yield put({
      type: 'user/SET_STATE',
      payload: {
        name: `${firstName} ${lastName}`,
        email,
      },
    })

    yield history.push('/auth/verify-email')
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider } = yield select((state) => state.settings)
  const authUser = yield call(mapAuthProviders[authProvider].currentAccount)

  if (!authUser) {
    yield history.push('/auth/login')
  } else {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        email: authUser.email,
      },
    })

    // Make sure the user is verified before allowing login
    if (!authUser.emailVerified) {
      yield history.push('/auth/verify-email')
    } else {
      const userFields = yield call(BackendHelper.get, 'user')
      const portfolioList = userFields.portfolios !== undefined ? userFields.portfolios : []

      const analytics = yield store.get('app.user.analytics') || {}

      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: authUser.uid,
          name: `${userFields.firstName} ${userFields.lastName}`,
          avatar: authUser.photoUrl,
          plan: userFields.pricingPlan,
          portfolioList,
          authorized: true,
          analytics,
        },
      })

      yield call(LOAD_LABELS)

      if (portfolioList.length > 0) {
        yield call(LOAD_CURRENT_PORTFOLIO, { payload: { portfolioID: portfolioList[0].id } })
      } else {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
            currentPortfolioLoading: false,
          },
        })
      }
    }
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_CURRENT_PORTFOLIO({ payload: { portfolioID } }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      currentPortfolioLoading: true,
    },
  })

  yield call(QUIET_LOAD_PORTFOLIO, {
    payload: { portfolioID },
  })

  yield put({
    type: 'user/SET_STATE',
    payload: {
      currentPortfolioLoading: false,
    },
  })
}

export function* QUIET_LOAD_PORTFOLIO({ payload: { portfolioID } }) {
  const [portfolio, snapshots, positions] = portfolioID
    ? yield all([
        call(BackendHelper.get, `portfolios/${portfolioID}`),
        call(BackendHelper.get, `portfolios/${portfolioID}/snapshots`),
        call(BackendHelper.get, `portfolios/${portfolioID}/positions`),
      ])
    : [{}, []]

  const position = yield positions.sort(Misc.sortByOptions(['open', 'closed', 'planned'], 'status'))[0] || {}

  const journalEntries = positions.length
    ? yield call(
        BackendHelper.get,
        `portfolios/${portfolioID}/positions/${position.id}/journalentries`,
      )
    : undefined

  yield put({
    type: 'user/SET_CURRENT_VALUE',
    payload: {
      setting: 'currentPortfolio',
      value: { ...portfolio, snapshots, positions },
    },
  })

  yield put({
    type: 'user/SET_CURRENT_VALUE',
    payload: {
      setting: 'currentPosition',
      value: { ...position, journalEntries },
    },
  })
}

export function* UPDATE_POSITION({ payload: { updatedPosition } }) {
  const { snapshots, positions, id } = yield select(({ user }) => user.currentPortfolio)
  const portfolioRefreshed = yield call(BackendHelper.get, `portfolios/${id}`);

  // Update or add
  const foundIdx = positions.findIndex((el) => el.id === updatedPosition.id)

  if(foundIdx !== -1) {
    positions[foundIdx] = updatedPosition
  } else {
    positions.push(updatedPosition)
  }


  yield put({
    type: 'user/SET_CURRENT_VALUE',
    payload: {
      setting: 'currentPortfolio',
      value: {
        ...portfolioRefreshed,
        snapshots,
        positions,
      },
    },
  })

  yield put({
    type: 'user/SET_CURRENT_VALUE',
    payload: {
      setting: 'currentPosition',
      value:  updatedPosition,
    },
  })

  yield store.set(`app.user.currentPosition`, { id: updatedPosition.id, name: updatedPosition.id })
}

export function* LOAD_LABELS() {
  const labels = yield call(BackendHelper.get, 'labels')

  yield call(UPDATE_LABELS, { payload: { labels } })
}

export function* UPDATE_LABELS({ payload: { labels } }) {
  const labelsMap = yield labels.reduce((acc, curr) => {
    acc[curr.name] = curr

    return acc
  }, {})

  yield put({
    type: 'user/SET_STATE',
    payload: {
      systemLabelMap: labelsMap,
    },
  })
}

export function* LOGOUT() {
  const { authProvider } = yield select((state) => state.settings)
  yield call(mapAuthProviders[authProvider].logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
      currentPortfolio: {},
      currentPosition: {},
      systemLabelMap: {},
      portfolioList: [],
    },
  })

  yield store.remove('app.user.currentPortfolio')
  yield store.remove('app.user.currentPosition')
}

export function* FORGOT({ payload }) {
  const email = payload

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const { authProvider: autProviderName } = yield select((state) => state.settings)
  const success = yield call(mapAuthProviders[autProviderName].forgot, email)

  if (success) {
    yield history.push('/')

    yield notification.success({
      message: 'Password Reset',
      description: 'If your account exists, you will receive an email to reset your password.',
    })
  }

  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeEvery(actions.RESEND_EMAIL, RESEND_EMAIL),
    takeEvery(actions.UPDATE_POSITION, UPDATE_POSITION),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOAD_CURRENT_PORTFOLIO, LOAD_CURRENT_PORTFOLIO),
    takeEvery(actions.QUIET_LOAD_PORTFOLIO, QUIET_LOAD_PORTFOLIO),
    takeEvery(actions.LOAD_LABELS, LOAD_LABELS),
    takeEvery(actions.UPDATE_LABELS, UPDATE_LABELS),
    takeEvery(actions.LOGOUT, LOGOUT),
    takeEvery(actions.FORGOT, FORGOT),
    takeEvery(actions.SET_CURRENT_VALUE, SET_CURRENT_VALUE),
    takeEvery(actions.SET_ANALYTICS, SET_ANALYTICS),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
