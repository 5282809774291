import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from 'layouts'

const routes = [
  // Dashboards
  {
    path: '/portfolio',
    Component: lazy(() => import('pages/portfolio')),
    exact: true,
  },

  // Stock Trading - Big Money, No Whammy Stuff
  {
    path: '/positions',
    Component: lazy(() => import('pages/positions')),
    exact: true,
  },
  // Page for adding existing portfolio & Orders
  {
    path: '/portfolioimport',
    Component: lazy(() => import('pages/portfolioImport')),
    exact: true,
  },
  // performance dashboard
  {
    path: '/analytics/performance',
    Component: lazy(() => import('pages/analytics/performance')),
    exact: true,
  },
  // strategy dashboard
  {
    path: '/analytics/strategy',
    Component: lazy(() => import('pages/analytics/strategy')),
    exact: true,
  },
  // Profile
  {
    path: '/profile',
    Component: lazy(() => import('pages/profile')),
    exact: true,
  },
  {
    path: '/notes',
    Component: lazy(() => import('pages/notes')),
    exact: true,
  },
  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/verify-email',
    Component: lazy(() => import('pages/auth/verify-email')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/payments',
    Component: lazy(() => import('pages/auth/payment')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => (
  <ConnectedRouter history={history}>
    <Layout>
      <Route
        render={(state) => {
          const { location } = state
          return (
            <SwitchTransition>
              <CSSTransition
                key={location.pathname}
                appear
                classNames={routerAnimation}
                timeout={routerAnimation === 'none' ? 0 : 300}
              >
                <Switch location={location}>
                  <Route exact path="/" render={() => <Redirect to="/portfolio" />} />
                  {routes.map(({ path, Component, exact }) => (
                    <Route
                      path={path}
                      key={path}
                      exact={exact}
                      render={() => (
                        <div className={routerAnimation}>
                          <Suspense fallback={null}>
                            <Component />
                          </Suspense>
                        </div>
                      )}
                    />
                  ))}
                  <Redirect to="/auth/404" />
                </Switch>
              </CSSTransition>
            </SwitchTransition>
          )
        }}
      />
    </Layout>
  </ConnectedRouter>
)

export default connect(mapStateToProps)(Router)
