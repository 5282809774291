import React from 'react'
// import Search from './Search'
import LanguageSwitcher from './LanguageSwitcher'
import ThemeSwitcher from './ThemeSwitcher'
import PortfolioSelector from './PortfolioSelector'
// import Actions from './Actions'
import UserMenu from './UserMenu'
import style from './style.module.scss'

const TopBar = () => (
  <div className={style.topbar}>
    <div className="mr-auto">{/* <Search /> */}</div>
    <div className="mr-4 d-xl-block">
      <PortfolioSelector />
    </div>
    <div className="mr-4 d-sm-block">
      <ThemeSwitcher />
    </div>
    <div className="mr-4 d-none d-sm-block">
      <LanguageSwitcher />
    </div>
    {/* <div className="mr-4 d-none d-sm-block">
        <Actions />
  </div> */}
    <div className="">
      <UserMenu />
    </div>
  </div>
)

export default TopBar
