import React, { useState } from 'react'
import {
  notification,
  Modal,
  Form,
  Input,
  InputNumber,
  Steps,
  Button,
  Typography,
  Space,
} from 'antd'
import { Link } from 'react-router-dom'
import { BackendHelper } from 'utils/index'
import { connect } from 'react-redux'
import './styles.scss'
import './override.css'

const mapStateToProps = ({ settings, user }) => ({
  addPortfolioModalOpen: settings.addPortfolioModalOpen,
  userId: user.id,
  portfolioList: user.portfolioList,
})
const { Title, Paragraph } = Typography
const { Step } = Steps

const AddPortfolioModal = (props) => {
  const { dispatch, addPortfolioModalOpen, userId, portfolioList } = props

  const [addPortfolioLoading, setAddPortfolioLoading] = useState(false)
  const [current, setCurrent] = useState(0)

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const finish = () => {
    setCurrent(0)
    closePortfolioModal()
  }

  const addPosition = () => {
    finish()
    openPositionModal()
  }

  const openPositionModal = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'addPositionModalIsOpen',
        value: true,
      },
    })
  }

  const closePortfolioModal = () => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'addPortfolioModalOpen',
        value: false,
      },
    })
  }

  const [form] = Form.useForm()

  const onCreatePortfolio = (values) => {
    setAddPortfolioLoading(true)
    createPortfolio(values.name, values.accountValue, values.broker).then((data) => {
      dispatch({
        type: 'user/SET_STATE',
        payload: {
          currentPortfolioLoading: true,
        },
      })

      dispatch({
        type: 'user/SET_CURRENT_VALUE',
        payload: {
          setting: 'currentPortfolio',
          value: data,
        },
      })

      dispatch({
        type: 'user/SET_CURRENT_VALUE',
        payload: {
          setting: 'currentPosition',
          value: {},
        },
      })

      form.resetFields()

      setAddPortfolioLoading(false)

      setCurrent(2)

      dispatch({
        type: 'user/SET_STATE',
        payload: {
          portfolioList: [...portfolioList, { name: data.name, id: data.id }],
          currentPortfolioLoading: false,
        },
      })

      notification.success({
        message: 'Portfolio Created',
        description: `A new portfolio called "${data.name}" was created!`,
      })
    })
  }

  async function createPortfolio(name, accountValue = 0, broker = '') {
    const brokerageAccount = {
      balance: Number(accountValue),
      netDeposits: 0,
      netWithdrawals: 0,
    }

    const body = { userId, name, brokerageAccount, broker }
    const data = await BackendHelper.post('portfolios', { body })

    return { ...data, snapshots: [], positions: [] }
  }

  const steps = [
    {
      title: 'Welcome',
      content: (
        <div className="text-center content">
          <i className="fe fe-book icon" />
          <Title level={4} className="model-title">
            Welcome to SwingTrackr Portfolios
          </Title>
          <Paragraph className="text-center">
            The next series of steps is going to help guide you in adding a portfolio. You can think
            of a portfolio directly linked to a specific broker
          </Paragraph>
          <br />
          <Paragraph>
            Want to add a portfolio that already has a number of held positions?
          </Paragraph>
          <Paragraph>
            <Link to="/portfolioimport">
              <Button onClick={closePortfolioModal} type="primary">
                Portfolio Import Tool
              </Button>
            </Link>
          </Paragraph>
        </div>
      ),
    },
    {
      title: 'General',
      content: (
        <div className="content">
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ modifier: 'public' }}
          >
            <Form.Item
              name="name"
              label="Portfolio Name"
              hasFeedback
              rules={[{ required: true, message: 'Your portfolio needs a name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="accountValue"
              hasFeedback
              label="Cash on Hand"
              rules={[{ type: 'number', min: 0 }]}
            >
              <InputNumber
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
            </Form.Item>
            <Form.Item name="broker" label="Broker" hasFeedback>
              <Input type="textarea" />
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      title: 'Add your first Position',
      content: (
        <div className="text-center content">
          <Title level={3} className="model-title">
            Your portfolio has been added!
          </Title>
          <Paragraph className="text-center">
            To add your first position, follow the prompt below, or click &quot;Finish&quot; to exit
            this screen.
          </Paragraph>
        </div>
      ),
    },
  ]

  const modalFooter = (
    <Space>
      {current === 0 && <Button onClick={() => next()}>Next</Button>}

      {current === 1 && (
        <div>
          <Button onClick={() => prev()}>Previous</Button>
          <Button
            type="primary"
            loading={addPortfolioLoading}
            onClick={() => {
              form.validateFields().then((values) => {
                onCreatePortfolio(values)
              })
            }}
          >
            Add Portfolio
          </Button>
        </div>
      )}

      {current === 2 && (
        <div>
          <Button onClick={() => finish()}>Finish</Button>
          <Button type="primary" loading={addPortfolioLoading} onClick={() => addPosition()}>
            Add Position
          </Button>
        </div>
      )}
    </Space>
  )

  return (
    <Modal
      title="Create a new portfolio"
      className="base-modal"
      centered
      visible={addPortfolioModalOpen}
      onCancel={closePortfolioModal}
      style={{ borderStyle: 'solid', borderColor: 'white' }}
      confirmLoading={addPortfolioLoading}
      footer={modalFooter}
    >
      <div>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action text-right">
          {/* {current === steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                form
                  .validateFields()
                  .then(values => {
                    onCreatePortfolio(values)
                  })
                  .then(message.success('Portfolio Created!'))
                  .catch(info => {
                    console.log('Validate Failed:', info)
                    message.error('Portfolio Error, please contact support!')
                  })
              }}
            >
              Done
            </Button>
          )} */}
        </div>
      </div>
    </Modal>
  )
}

export default connect(mapStateToProps)(AddPortfolioModal)
