export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Home',
    },
    {
      title: 'Portfolio',
      key: 'portfolio',
      icon: 'fe fe-home',
      url: '/portfolio',
    },
    {
      title: 'Positions',
      key: 'positions',
      icon: 'fe fe-list',
      url: '/positions',
    },
    {
      title: 'Notes',
      key: 'notes',
      icon: 'fe fe-book-open',
      url: '/notes',
    },
    {
      title: 'Analytics',
      key: 'analytics',
      icon: 'fe fe-pie-chart',
      url: '/analytics',
      // roles: ['admin'], // set user roles with access to this route
      count: 2,
      children: [
        {
          title: 'Performance',
          key: 'performance',
          url: '/analytics/performance',
        },
        {
          title: 'Strategy',
          key: 'strategy',
          url: '/analytics/strategy',
        },
      ],
    },
    {
      category: true,
      title: 'Apps (Coming Soon)',
    },
    {
      title: 'Signals',
      key: 'signals',
      icon: 'fe fe-activity',
      url: '/portfolio',
      disabled: true,
    },
    {
      title: 'Chat',
      key: 'chat',
      icon: 'fe fe-message-circle',
      url: '/portfolio',
      disabled: true,
    },
    {
      title: 'Workspaces',
      key: 'workspaces',
      icon: 'fe fe-briefcase',
      url: '/portfolio',
      disabled: true,
    },
  ]
}
